<template>
  <v-dialog max-width="500">
    <template v-slot:activator="{ props: activatorProps }">
      <v-btn v-bind="activatorProps" variant="flat" color="primary"
        ><v-icon class="mr-2">mdi-heart</v-icon>Doneer</v-btn
      >
    </template>

    <template v-slot:default="{ isActive }">
      <v-card>
        <div class="pa-4 bg-primary d-flex align-center justify-space-between">
          <h2>Doneer nu</h2>
          <v-btn
            icon="mdi-close"
            variant="text"
            @click="isActive.value = false"
          ></v-btn>
        </div>
        <div class="pa-4" v-if="!send">
          <div
            class="mb-3"
            style="display: grid; grid-template-columns: 1fr 1fr; gap: 10px"
          >
            <v-text-field
              variant="solo"
              flat
              bg-color="smoke"
              label="Voornaam"
              hide-details=""
              v-model="donation.firstName"
            ></v-text-field>
            <v-text-field
              v-model="donation.lastName"
              variant="solo"
              flat
              bg-color="smoke"
              label="Achternaam"
              hide-details=""
            ></v-text-field>
          </div>
          <v-text-field
            v-model="donation.email"
            variant="solo"
            flat
            hide-details=""
            bg-color="smoke"
            label="E-mail"
          ></v-text-field>
          <!-- <v-btn-toggle
            color="primary"
            style="width: 100%"
            v-model="donation.recurring"
          >
            <v-btn value="eenmalig" width="50%"> Eenmalig </v-btn>
            <v-btn value="maandelijks" width="50%">Maandelijks</v-btn>
          </v-btn-toggle> -->

          <p style="font-size: 10pt" class="mt-4 mb-1">
            Kies uw {{ donation.recurring }}e bijdrage:
          </p>
          <div class="d-flex flex-wrap" style="gap: 7px">
            <v-btn
              @click="donation.value = a.value"
              :color="donation.value === a.value ? 'primary' : 'smoke'"
              variant="flat"
              v-for="a in amounts"
              :key="a.value"
              ><span>{{ a.value > 0 ? `€ ${a.value}` : "Anders" }}</span></v-btn
            >
          </div>
        </div>
        <div class="pa-10" v-else>
          <p class="mb-3">
            <b>Hartelijk dank voor uw donatie! </b>Onze donatie-link opent
            automatisch in een nieuw tabblad.
          </p>
          <v-btn @click="retry" flat color="smoke">Probeer opnieuw</v-btn>
        </div>

        <v-card-actions v-if="!send">
          <v-spacer></v-spacer>
          <div>
            <v-btn
              @click="doneer"
              :disabled="
                !donation.value ||
                !donation.recurring ||
                !donation.firstName ||
                !donation.lastName ||
                !donation.email
              "
              color="primary"
              >Doneer</v-btn
            >
          </div>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import { collection, addDoc } from "firebase/firestore";
import { db } from "@/firebase";
export default {
  data() {
    return {
      amounts: [
        {
          link: "https://betaalverzoek.rabobank.nl/betaalverzoek/?id=W_v-JJSBTxOtQCGxuKlkKQ",
          value: 50,
        },
        {
          value: 35,
          link: "https://betaalverzoek.rabobank.nl/betaalverzoek/?id=84GCnVubQ_iLRzcHscj5Ng",
        },
        {
          value: 25,
          link: "https://betaalverzoek.rabobank.nl/betaalverzoek/?id=iImFJhKeSaeWZ2VMIM7lwQ",
        },
        {
          value: 15,
          link: "https://betaalverzoek.rabobank.nl/betaalverzoek/?id=vQXbGGHQR8WavfOOQD34sg",
        },
        {
          value: "Anders",
          link: "https://betaalverzoek.rabobank.nl/betaalverzoek/?id=176fBEGMSleC4cxQi-VkTw",
        },
      ],
      send: false,
      donation: {
        firstName: null,
        lastName: null,
        email: null,
        value: null,
        recurring: "eenmalig",
        date: Math.floor(Date.now() / 1000),
      },
    };
  },
  methods: {
    async doneer() {
      await addDoc(collection(db, "donaties"), this.donation);

      if (this.donation.recurring === "eenmalig") {
        this.openLink(
          this.amounts.find((a) => a.value === this.donation.value).link
        );
        this.send = true;
      }
    },
    openLink(link) {
      window.open(link, "_blank");
    },
    retry() {
      this.openLink(
        this.amounts.find((a) => a.value === this.donation.value).link
      );
    },
  },
};
</script>
