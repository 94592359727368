<template>
  <section class="home-container">
    <div class="section" style="padding-bottom: 7vh">
      <div style="position: relative">
        <v-img
          height="30vh"
          cover
          rounded=""
          class="elevation-4 image"
          src="https://images.pexels.com/photos/220455/pexels-photo-220455.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
        ></v-img>
        <v-card
          width="80%"
          min-width="250px"
          class="homecard pa-8 text-center text-background elevation-4"
          color="primary"
        >
          <h2>WeShare4Kids biedt een nieuwe start voor het gehele gezin.</h2>
        </v-card>
      </div>
    </div>

    <div class="quick section">
      <v-card
        class="pa-8 elevation-2 text-center align-center d-flex flex-column justify-center"
      >
        <!-- <v-img class="my-4" width="65" src="@/assets/logo.svg"></v-img> -->
        <h2>Over ons</h2>
        <p class="mt-2 mb-6">
          Wij helpen gezinnen waar een kind ernstig ziek is (geweest) de nieuwe
          start te veraangenamen, om een moeilijke periode met elkaar te kunnen
          afsluiten, samen weer op te laden en het begin van een nieuwe toekomst
          te kunnen vieren.
        </p>
        <v-btn @click="$router.push('/overons')" variant="outlined"
          >Lees verder</v-btn
        >
      </v-card>
      <v-card
        class="pa-8 elevation-2 align-center text-center d-flex flex-column justify-center"
      >
        <!-- <v-img class="my-4" width="65" src="@/assets/logo.svg"></v-img> -->
        <h2>Hoe werkt het</h2>
        <p class="mt-2 mb-6">
          Via onze digitale marktplaats komen vraag en aanbod samen, zodat deze
          gezinnen even kunnen ontsnappen en op adem kunnen komen na een zware
          periode. Sluit u aan en deel uw plek voor een goed gevoel en een groot
          verschil.
        </p>
        <v-btn variant="outlined" @click="$router.push('/overons#werkwijze')"
          >Lees verder</v-btn
        >
      </v-card>
      <v-card
        class="pa-8 elevation-2 align-center text-center d-flex flex-column justify-center"
      >
        <!-- <v-img class="my-4" width="65" src="@/assets/logo.svg"></v-img> -->
        <h2>Doneer nu</h2>
        <p class="mt-2 mb-6">
          Donaties zijn de belangrijkste bron van onze inkomsten. Ziet u het
          belang van WeShare4Kids en wilt u ook een financiële bijdrage doen,
          dan heel graag! De gezinnen zijn daar heel erg blij mee.
        </p>

        <DoneerKnoppen />
      </v-card>
    </div>

    <div class="section split">
      <div class="d-flex align-center">
        <v-img
          :src="require('@/assets/stock/ziekte.jpg')"
          :eager="true"
          style="border-radius: 12px"
        ></v-img>
      </div>
      <div class="textpart d-flex flex-column justify-center align-start">
        <h2 style="font-size: 22pt">Kinderen en ernstige ziektes</h2>
        <p class="mt-4" style="font-size: 14pt">
          Wij zijn van mening dat elk kind recht heeft op een zorgeloze jeugd,
          gevuld met liefde, vreugde en kansen. Helaas worden veel kinderen en
          dus gezinnen geconfronteerd met de harde realiteit van ernstige
          ziekten, die niet alleen fysiek, maar ook emotioneel en mentaal heftig
          ingrijpen. Wanneer een gezin door deze moeilijke tijd heen navigeert,
          is het van cruciaal belang dat ze de ondersteuning en middelen hebben
          om te herstellen en hun leven weer op te bouwen.
        </p>
        <p class="my-4" style="font-size: 14pt">
          Samen bouwen we aan een wereld waarin elk kind de kans krijgt om te
          gedijen, ongeacht de uitdagingen waarmee het wordt geconfronteerd. Wij
          willen een positief verschil maken in het leven van kinderen en
          gezinnen die het heel hard nodig hebben. Met uw steun kan dat. Laten
          we daarom samen ons ‘tweede huis’ delen en zo bijdragen aan een betere
          toekomst voor alle kinderen.
        </p>
        <!-- <v-btn variant="outlined">Lees verder</v-btn> -->
      </div>
    </div>

    <div class="section split reverse">
      <div class="textpart d-flex flex-column justify-center align-start">
        <h2 style="font-size: 22pt">Zware periode voor het hele gezin</h2>
        <p class="mt-4" style="font-size: 14pt">
          Het is voor gezinnen waarin een kind lijdt of heeft geleden aan een
          ernstige ziekte moeilijk om de overgang te maken van de
          behandelingsfase terug naar het 'normale' leven. In deze periode
          ontbreekt vaak de vertrouwde gezinsdynamiek en zijn de energiereserves
          uitgeput, terwijl nieuwe rituelen en een nieuwe mindset nog moeten
          worden gevonden.
        </p>
        <p class="my-2" style="font-size: 14pt">
          Om wat voor ernstige aandoening het ook gaat, een medisch traject
          vergt uiteraard heel veel van het kind, maar evenzeer van de
          gezinsleden: de ouders en de broertjes en zusjes. De zorg en
          onzekerheid trekken gedurende de gehele behandelperiode een zware
          wissel op alle leden van het gezin. De vertrouwde rust en gerustheid
          verdwijnen en de gezinsdynamiek ondergaat vaak een totale verandering.
          Vaak zullen bepaalde zaken moeten wijken om herstel te bespoedigen. De
          andere kinderen zullen dat waarschijnlijk niet altijd begrijpen. Zo
          kunnen zij dan ook niet ongedwongen hun gang gaan en genieten van
          alledaagse momenten zoals naar sport- of muziekles gaan. Of gewoon
          spelen met vriendjes of vriendinnetjes……
        </p>
        <p style="font-size: 14pt" class="mb-4">
          Daarom streven we met WeShare4Kids ernaar om een gratis
          ‘gezinsverblijf’ aan te bieden, gericht op het ondersteunen van
          gezinnen na de behandeling van ernstige ziekten bij kinderen.
        </p>
        <!-- <v-btn variant="outlined">Lees verder</v-btn> -->
      </div>
      <div class="d-flex align-center">
        <v-img
          :src="require('@/assets/stock/ziekenhuis.jpg')"
          :eager="true"
          style="border-radius: 12px"
        ></v-img>
      </div>
    </div>

    <div class="section split">
      <div class="d-flex align-center">
        <v-img
          :src="require('@/assets/stock/strand.jpg')"
          :eager="true"
          style="border-radius: 12px"
        ></v-img>
      </div>
      <div class="textpart d-flex flex-column justify-center align-start">
        <h2 style="font-size: 22pt">Een nieuwe start..</h2>
        <p class="mt-4" style="font-size: 14pt">
          WeShare4Kids streeft ernaar een lichtpuntje te zijn in de duisternis,
          een bron van steun en hoop voor gezinnen die getroffen zijn door
          ziekte. Wij geloven in de kracht van gemeenschapszin en solidariteit,
          en wij willen een platform bieden waarop mensen elkaar kunnen vinden
          en ondersteunen. Onze visie is gebaseerd op het principe van
          wederkerigheid: door te delen en elkaar te helpen, kunnen we samen de
          lasten verlichten en de weg naar genezing en herstel effenen.
        </p>
        <p class="mt-2 mb-4" style="font-size: 14pt">
          Stichting WeShare4Kids richt zich op kinderen met een ernstige ziekte
          en op het weer bij elkaar brengen van het gezin in een ontspannen
          sfeer, door een gratis verblijf in een andere (vakantie-) omgeving aan
          te bieden. Uiteraard pas als dit medisch gesproken verantwoord is. Een
          dergelijk verblijf kan kosteloos worden aangeboden, vanuit door
          eigenaren voor dit doel gratis beschikbaar gestelde
          (vakantie)woningen. Je zou zo’n verblijf kunnen zien als een soort van
          nieuw ‘oplaad- of bijtank-moment’, misschien zelfs als een nieuwe
          start voor het gehele gezin.
        </p>
        <!-- <v-btn variant="outlined">Lees verder</v-btn> -->
      </div>
    </div>

    <div class="quote section d-flex justify-center">
      <div class="text-center">
        <p class="text-primary" style="font-weight: 600; font-size: 12pt">
          Prinses Máxima Centrum voor kinderoncologie:
        </p>
        <h1 class="my-4">
          Jaarlijks krijgen ongeveer 600 kinderen in Nederland kanker.
        </h1>
        <p class="px-10" style="font-size: 14pt">
          Nog steeds overlijdt één op de vier kinderen met kanker aan deze
          ziekte. Als een kind ernstig ziek is door kanker, staat maar een ding
          voorop: genezen.
        </p>
        <!-- <v-btn class="mt-8" variant="outlined" color="">Lees verder</v-btn> -->
      </div>
    </div>

    <div class="section">
      <h2 class="text-center" style="font-size: 22pt">Ons laatste nieuws</h2>
      <p
        class="text-center"
        style="cursor: pointer"
        @click="$router.push('/nieuws#alles')"
      >
        <u>Bekijk alle artikelen</u>
      </p>
      <div class="nieuws mt-8">
        <v-card class="elevation-2" v-for="(n, index) in nieuws" :key="index">
          <v-img rounded :src="n.header_image"></v-img>
          <div class="pa-8">
            <p>{{ n.location + " " + getDate(n.timestamp) }}</p>
            <p class="my-2" style="font-size: 18pt; font-weight: 600">
              {{ n.title }}
            </p>
            <p class="mt-4">
              {{ n.subtitle }}
            </p>
            <v-btn
              class="mt-6"
              variant="outlined"
              @click="$router.push(`/nieuws/${n.uid}`)"
              >Lees verder
              <v-icon class="ml-2" size="16">mdi-arrow-right</v-icon></v-btn
            >
          </div>
        </v-card>
      </div>
    </div>

    <SponsorSection />
  </section>
</template>

<script>
import SponsorSection from "@/components/SponsorSection.vue";
import { mapState } from "vuex";
import { DateTime } from "luxon";
import DoneerKnoppen from "@/components/DoneerKnoppen.vue";

export default {
  components: {
    SponsorSection,
    DoneerKnoppen,
  },
  data() {
    return {
      reveal: false,
    };
  },
  computed: {
    ...mapState({
      nieuws: (state) => state.nieuws,
    }),
  },
  methods: {
    getDate(d) {
      let i = DateTime.fromSeconds(d);
      return i.toLocaleString(DateTime.DATETIME_FULL);
    },
  },
};
</script>
<style lang="scss" scoped>
.homecard {
  position: absolute;
  bottom: -75px;
  left: 50%;
  transform: translate(-50%, 0);

  h1 {
    font-size: 26pt;
    line-height: 35pt;
  }
}

.split {
  display: grid;
  grid-template-columns: 3fr 4fr;
  gap: 5vw;

  .textpart {
    padding: 0 0 0 10%;
  }

  &.reverse {
    grid-template-columns: 4fr 3fr;
    .textpart {
      padding: 0 10% 0 0;
    }
  }
}

.quick {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 25px;
}

.nieuws {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-auto-flow: column;
  gap: 50px;
}

.quote {
  div {
    max-width: 85vw;
  }
  h1 {
    font-size: 40pt;
    line-height: 50pt;
  }
}

@media screen and (max-width: 700px) {
  .homecard {
    bottom: -150px;
    h1 {
      font-size: 22pt;
    }
  }

  .quick {
    grid-template-columns: 1fr;
  }
  .quote {
    h1 {
      font-size: 32pt;
      line-height: 40pt;
    }
  }
  .split {
    display: grid;
    grid-template-columns: 1fr;
    gap: 5vw;

    .textpart {
      padding: 0;
    }

    &.reverse {
      grid-template-columns: 1fr;
    }
  }

  .reverse div:nth-child(1) {
    order: 1;
  }
  .nieuws {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-flow: row;
    gap: 50px;
  }
}
</style>
