<template>
  <div>
    <div>
      <v-navigation-drawer :permanent="true">
        <div
          class="pa-7 d-flex justify-center align-center"
          style="cursor: pointer"
          @click="$router.push('/')"
        >
          <v-img height="100px" src="@/assets/logo.svg"></v-img>
        </div>
        <v-divider></v-divider>
        <div class="pa-4">
          <v-btn v-if="!user" @click="google" block color="primary"
            >Login</v-btn
          >
          <div v-if="user" style="font-size: 14pt" class="pa-2">
            Welkom, {{ user.displayName.split(" ")[0] }}!
          </div>
        </div>
        <div v-if="user">
          <v-list-item
            link
            title="Nieuws"
            prepend-icon="mdi-post-outline"
            to="/admin/nieuws"
          ></v-list-item>
          <v-list-item
            link
            to="/admin/nieuwsbrief"
            title="Nieuwsbrief"
            prepend-icon="mdi-email"
          ></v-list-item>
          <v-list-item
            link
            to="/admin/donaties"
            title="Donaties"
            prepend-icon="mdi-currency-eur"
          ></v-list-item>
        </div>
        <template v-slot:append>
          <div class="pa-4">
            <v-btn v-if="user" @click="logout" variant="outlined" block
              >Log out</v-btn
            >
          </div>
        </template>
      </v-navigation-drawer>
    </div>
    <router-view v-if="user" v-slot="{ Component, route }">
      <transition name="slide-fade" mode="out-in">
        <component :is="Component" :key="route.path" />
      </transition>
    </router-view>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
} from "firebase/auth";

export default {
  data() {
    return {
      access: false,
      nieuws: [],
      password: "",
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  methods: {
    async logout() {
      const auth = getAuth();
      signOut(auth);
      this.$store.commit("setUser", null);
    },
    async google() {
      const provider = new GoogleAuthProvider();
      const auth = getAuth();
      signInWithPopup(auth, provider)
        .then((result) => {
          const user = result.user;
          this.$store.commit("setUser", user);
          this.$store.dispatch("getNieuws");
          this.$store.dispatch("getNieuwsbrieven");
          this.$store.dispatch("getDonaties");
        })
        .catch((error) => {
          console.log(error.message);
          alert(error.message);
        });
    },
  },
};
</script>
