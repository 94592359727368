// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";

// Vuetify
import { createVuetify } from "vuetify";

export default createVuetify({
  defaults: {
    VBtn: {
      style: "text-transform: none; font-weight: 500",
    },
  },
  theme: {
    themes: {
      light: {
        dark: false,
        colors: {
          primary: "#FB5D0D",
          background: "#FFF2EB",
          "background-darken": "#FFE4D6",
          secondary: "#501C01",
          tertiary: "#FC9C4C",
          light: "#FCB061",
          sun: "#FCE393",
          smoke: "#ebebef",
        },
      },
    },
  },
});
// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
