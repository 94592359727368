<template>
  <div
    id="nieuwsbrief"
    class="nieuwsbrief py-10 d-flex justify-space-between align-center flex-wrap"
  >
    <h2>Schrijf je in voor onze nieuwsbrief</h2>
    <v-text-field
      v-if="!success"
      placeholder="Uw emailadres"
      variant="solo"
      max-width="400"
      hide-details=""
      flat
      bg-color="white"
      :loading="loading"
      v-model="email"
    >
      <template v-slot:append-inner>
        <v-btn
          @click="inschrijven"
          :disabled="!email || !email.includes('@')"
          :loading="loading"
          variant="flat"
          color="primary"
          >Inschrijven</v-btn
        >
      </template></v-text-field
    >
    <p v-else>U bent ingeschreven!</p>
  </div>
</template>

<script>
import { db } from "@/firebase";
import { collection, addDoc, doc, updateDoc } from "firebase/firestore";

export default {
  data() {
    return {
      email: "",
      loading: false,
      success: false,
    };
  },
  methods: {
    async inschrijven() {
      this.loading = true;
      try {
        const docRef = await addDoc(collection(db, "nieuwsbrief"), {
          email: this.email,
          date: new Date(),
        });
        await updateDoc(doc(db, "nieuwsbrief", docRef.id), {
          uid: docRef.id,
        });
        console.log("Document written with ID: ", docRef.id);
        this.success = true;
      } catch (e) {
        console.error("Error adding document: ", e);
      }

      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.nieuwsbrief {
  max-width: 1200px;
  width: 90vw;
  margin: auto;
}

@media screen and (max-width: 700px) {
  .nieuwsbrief {
    max-width: 90%;
  }
}
</style>
